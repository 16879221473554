var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-clock-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v("Logs de Acessos")])]},proxy:true}])},[_c('v-text-field',{staticClass:"ml-auto mt-4 break-search bs-logs",staticStyle:{"max-width":"250px"},attrs:{"id":"logs-historico-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"logs-table",attrs:{"headers":[
            {
              text: 'Empresa',
              value: 'empresa'
            },
            {
              text: 'Usuário',
              value: 'usuario'
            },
            {
              text: 'Endereço IP',
              value: 'ip'
            },
            {
              text: 'Data e hora',
              value: 'data_hora_acesso'
            },
            {
              text: 'Mensagem de log',
              value: 'mensagem'
            }
          ],"items":_vm.logs,"search":_vm.search,"headerProps":{
            sortByText: 'Ordenar Por'
          },"footer-props":{
            itemsPerPageText: 'Itens por Página'
          },"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-results-text":"Nenhum registro correspondente encontrado"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item.mensagem",fn:function(ref){
          var item = ref.item;
return [_c('strong',{staticStyle:{"color":"#1976d2"},attrs:{"title":item.detalhes}},[_vm._v(" "+_vm._s(item.mensagem)+" ")])]}},{key:"item.data_hora_acesso",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseToDateTimeBR")(item.data_hora_acesso))+" ")]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.getLogsAcessos()}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar os log's de acesso")])])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }