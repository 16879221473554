<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <base-material-card
          color="primary"
          icon="mdi-clock-outline"
          inline
          class="px-5 py-4 ml-0"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">Logs de Acessos</div>
          </template>

          <v-text-field
            id="logs-historico-search"
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto mt-4 break-search bs-logs"
            label="Procurar"
            hide-details
            single-line
            style="max-width: 250px"
          />

          <v-divider class="mt-10" />

          <v-data-table
            class="logs-table"
            :headers="[
              {
                text: 'Empresa',
                value: 'empresa'
              },
              {
                text: 'Usuário',
                value: 'usuario'
              },
              {
                text: 'Endereço IP',
                value: 'ip'
              },
              {
                text: 'Data e hora',
                value: 'data_hora_acesso'
              },
              {
                text: 'Mensagem de log',
                value: 'mensagem'
              }
            ]"
            :items="logs"
            :search.sync="search"
            :headerProps="{
              sortByText: 'Ordenar Por'
            }"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            :mobile-breakpoint="800"
            :loading="loading"
            :hide-default-header="loading"
            :hide-default-footer="loading"
            no-results-text="Nenhum registro correspondente encontrado"
          >
            <template v-slot:[`item.mensagem`]="{ item }">
              <strong
                style="color: #1976d2"
                :title="item.detalhes"
              >
                {{ item.mensagem }}
              </strong>
            </template>
            <template v-slot:[`item.data_hora_acesso`]="{ item }">
              {{ item.data_hora_acesso | parseToDateTimeBR }}
            </template>
            <template v-slot:[`footer.page-text`]>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    @click="getLogsAcessos()"
                    >mdi-refresh</v-icon
                  >
                </template>
                <span>Clique aqui para recarregar os log's de acesso</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LogsAcessosUsuariosService from '@/services/LogsAcessosUsuariosService';
import refreshDataMixins from '@/mixins/refreshDataMixins';

export default {
  name: 'LogsAcessosUsuariosListar',

  mixins: [refreshDataMixins],

  data() {
    return {
      logs: [],
      search: '',
      loading: false
    };
  },

  mounted() {
    this.refreshData(this.getLogsAcessos);
  },

  methods: {
    getLogsAcessos() {
      this.logs = [];
      this.loading = true;

      LogsAcessosUsuariosService.getLogs()
        .then(({ data }) => {
          this.logs = data.data;
        })
        .catch((error) => {
          console.error(error);
          this.$toast.error(
            'Erro ao recuperar os logs de acessos de usuários.',
            '',
            { position: 'topRight' }
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style>
@media screen and (min-width: 800px) {
  .logs-table table tr td:nth-child(2) {
    max-width: 200px;
  }

  .logs-table table tr td:nth-child(5),
  .logs-table table tr td:nth-child(6) {
    white-space: nowrap !important;
  }
}

@media screen and (max-width: 480px) {
  .bs-logs {
    width: 215.36px !important;
  }
}
</style>
